<template>
    <div>
        <Navbar />

        <div class="container-fluid">
            <br />
            <b-row>
                <b-col cols="12">
                    <h3>Introduction</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour commencer à utiliser
                        <span class="custom-text-color"
                            >Kalkin PDF-Kreator</span
                        >
                        voici une petite documentation vous permattant de
                        générer et de personnalisé vos fiches grace à cet
                        outils.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h3>Accueil</h3>
                </b-col>
            </b-row>
            <b-row mt-4>
                <b-col cols="12">
                    <p>
                        Sur la page d'accueil, deux listes se présentes à vous.
                        La liste de gauche permet de gérer les styles de vos
                        PDFs et celle de la droite les générations.
                        <br />

                        Un style est l'ensemble des élements de style d'un
                        template qui sont customisables. Par exemple des
                        couleurs de textes, de formes ou l'importation d'images
                        de header et de footer. Les styles sont propres à un
                        template et ils sont obligatoires pour créer une
                        génération
                        <br />
                        Une génération est un ensemble de PDF sauvegardé.
                        L'outil à été pensé pour pouvoir créer un grand jeu de
                        PDF d'un seul coup. Une génération est dépendante de son
                        style et du template qui lui est associé.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h3>Gestion des styles</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Création d'un nouveau style</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour créer un nouveau style, il faut clicker sur
                        <span class="custom-text-color">Ajouter un style +</span
                        >.<br />
                        Vous arriverez sur la page de création d'un style. Cette
                        page est séparé en deux parties. A gauche le panneau de
                        configuration et à droite la prévisualisation. Toute les
                        modifications apporté au panneau de configuration seront
                        appliqué en temps réel à la prévisualisation. Par défaut
                        le template est le template Kalkin Basic. Vous pouvez
                        aller dans la rubrique
                        <span class="custom-text-color-secondary"
                            >Template</span
                        >
                        pour modifier le template actif dans la liste
                        déroulante. Dans la rubrique
                        <span class="custom-text-color-secondary">Style</span>
                        vous allez rentrer les données de styles en fonction du
                        template que vous avez séléctionné. Pour les couleurs
                        grâce au selecteur de couleur et pour les images aux
                        inputs. La rubrique
                        <span class="custom-text-color-secondary"
                            >Exemple de données</span
                        >
                        permet de contrôler la donnée de contenu de la
                        prévisualisation qui est situé à droite du panneau. Vous
                        avez une liste de champs de formulaire qui sont déjà
                        pré-rempli et vous pouvez ajouter un tracé dans la
                        partie Visualiser mon tracé, en indiquantle type de
                        tracé (Lambert93 ou Wgs) et en clickant sur
                        <span class="custom-text-color-secondary"
                            >Générer la carte</span
                        >.<br />
                        Une fois que vous avez rentré vos données de styles et
                        rentré son nom dans le champs en haut à gauche vous
                        pouvez sauvegarder votre style grâce au bouton de
                        sauvegarde
                        <span class="custom-text-color-secondary">
                            <b-icon-cloud-download></b-icon-cloud-download></span
                        >.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Modification d'un style</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour modifier un style clickez sur le bouton modifier
                        dans la liste des styles de la page d'accueil. Vous
                        retournerez sur la même page que lors de la création
                        d'un style mais avec les données enregistrés. N'oubliez
                        pas de sauvegarder les modifications grâces au bouton en
                        haut à gauche. Sachez que vous ne pouvez pas modifier le
                        template d'un style déjà créee.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Suppression d'un style</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour supprimer un style clickez sur le bouton supprimer
                        dans la liste des styles de la page d'accueil. Attention
                        la suppresion d'un style entrainera la suppresion des
                        génération de pdf qui lui sont associé.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h3>Gestion des générations</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Création d'une nouvelle génération</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour créer une nouvelle génération, il faut clicker sur
                        <span class="custom-text-color"
                            >Créer une génération +</span
                        >. Une pop-up va s'ouvrir ou vous devrez sélectionner le
                        template puis le style pour lequel vous voulez créer une
                        nouvelle génération et son nom. Vous arriverez sur la
                        page d'edition d'une génération.<br />
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Edition d'une génération</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Après avoir créer une nouvelle génération ou avoir
                        clicker sur le bouton modifier afin de modifier une
                        génération existante vous arrivez sur la page d'edition
                        d'une génération. La barre de tâche permet d'effectuer
                        différentes actions. Le bouton
                        <span class="custom-text-color-secondary">
                            <b-icon-cloud-download></b-icon-cloud-download
                        ></span>
                        permet de sauvegarder les modifications apporté à la
                        génération. Le bouton
                        <span class="custom-text-color-secondary">
                            <b-icon-download></b-icon-download
                        ></span>
                        permet de générer les pdfs dans une archive. Le bouton
                        <span class="custom-text-color-secondary">
                            <b-icon-file-earmark-plus></b-icon-file-earmark-plus
                        ></span>
                        permet d'ajouter un nouveau pdf à la génération. Ce pdf
                        prendra comme nom celui saisi dans le champs de
                        formualire situé à sa gauche. Le bouton
                        <span class="custom-text-color-secondary">
                            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise
                        ></span>
                        permet de réinitialiser la génération et de revenir à sa
                        dernière sauvegarde.<br />
                        Lorsque vous avez ajouter un ou plusieurs pdf, vous
                        pouvez saisir sa donnée en sélectionnant ce pdf en
                        clickant dessus dans la liste situé à gauche. Il est
                        important de remplir chaque champs de formulaire avant
                        de générer les pdfs. La petite croix permet de supprimer
                        un pdf de la génération
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h4>Suppression d'une génération</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p>
                        Pour supprimer une génération clickez sur le bouton
                        supprimer dans la liste des générations de la page
                        d'accueil
                    </p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/layouts/Navbar.vue";

export default {
    name: "Wiki",
    components: { Navbar },
};
</script>
<style scoped>
.align-center {
    display: flex; /* establish flex container */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */
}
.custom-text-color {
    color: #0c8d7b;
}
.custom-text-color-secondary {
    color: #92bb3f;
}
h3 {
    color: #0a695d;
}
h4 {
    margin-left: 1em;
    color: #0c8d7b;
}
p {
    font-size: 1.2em;
    padding: 10px;
    width: 95%;
    margin: 10px auto;
    text-align: justify;
}
#home1 {
    width: 1500px;
}
</style>
